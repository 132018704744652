html,
body,
div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: system, sans-serif;
}

.app-config {
    display: flex;
    flex-direction: column;
    align-items: center;

    form,
    .note {
        width: 800px;
        margin-top: 50px;
    }
}
